import React from "react"
import { Snackbar, TextField, withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import MuiAlert from "@material-ui/lab/Alert"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameholder: "",
      companyholder: "",
      linkedinHolder: "",
      emailholder: "",
      messageholder: "",
      submitted: false,
      errors: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = e => {
    e.preventDefault()
    const {
      nameholder,
      companyholder,
      phoneholder,
      emailholder,
      messageholder,
    } = this.state
    const errors = []
    const pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,4}$/

    if (nameholder.length < 3) {
      errors.push("nameholder")
    }
    if (!pattern.test(emailholder)) {
      errors.push("emailholder")
    }
    if (messageholder.length <= 10) {
      console.log("message length is less than 10")
      errors.push("messageholder")
    }
    if (errors.length === 0) {
      fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          name: nameholder,
          company: companyholder,
          email: emailholder,
          phone: phoneholder,
          message: messageholder,
        }),
      })
        .then(() => this.setState({ submitted: true }))
        .catch(error => {
          errors.push(
            "Ocurrió un error al enviar tu mensaje. Por favor intenta enviar tu mensaje nuevamente.",
          )
        })
    }
    this.setState({ errors: errors })
  }

  Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

  render() {
    const {
      nameholder,
      companyholder,
      emailholder,
      phoneholder,
      messageholder,
      submitted,
      errors,
    } = this.state

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {/* bot field */}
          <TextField name={"bot-field"} type={"hidden"} style={{ display: "none" }} />

          <TextField name="nameholder" label="Nombre *" placeholder="Nombre"
                     value={nameholder}
                     error={errors.includes("nameholder")}
                     helperText={errors.includes("nameholder") ? "Por favor ingresá un nombre válido." : ""}
                     onChange={this.handleChange}
                     onInvalid={() => "some error"}
                     className={this.props.classes.formField}
          />
          <TextField name="companyholder" label="Empresa" placeholder="Empresa"
                     value={companyholder}
                     error={errors.includes("companyholder") ? "Por favor ingresá un nombre de empresa válido." : ""}
                     helperText={errors.includes("companyholder")}
                     onChange={this.handleChange}
                     className={this.props.classes.formField}
          />
          <TextField name="emailholder" label="Email *" placeholder="Email"
                     value={emailholder}
                     error={errors.includes("emailholder")}
                     helperText={errors.includes("emailholder") ? "Por favor ingresá un email válido." : ""}
                     onChange={this.handleChange}
                     className={this.props.classes.formField}
          />
          <TextField name="phoneholder" label="Teléfono" placeholder="Teléfono"
                     value={phoneholder}
                     error={errors.includes("phoneholder")}
                     helperText={errors.includes("phoneholder") ? "Por favor ingresá un teléfono válido." : ""}
                     onChange={this.handleChange}
                     className={this.props.classes.formField}
          />
          <TextField multiline name={"messageholder"} label={"Mensaje *"} placeholder={"Deja tu mensaje..."}
                     value={messageholder}
                     error={errors.includes("messageholder")}
                     helperText={errors.includes("messageholder") ? "Por favor ingresá un mensaje válido." : ""}
                     onChange={this.handleChange}
                     className={this.props.classes.formField}
                     style={{ width: "86.5%" }}
          />

          <Button type={"submit"} className={this.props.classes.contactButton}>
            {"Enviar mensaje"}
          </Button>

          <Snackbar open={submitted} autoHideDuration={6000} style={{ bottom: "3em" }}>
            <MuiAlert elevation={3} variant="standard" severity="success">{"Mensaje enviado!"}</MuiAlert>
          </Snackbar>
          <Snackbar open={errors.length !== 0} autoHideDuration={6000} style={{ bottom: "3em" }}>
            <MuiAlert elevation={6} variant="standard" severity="error">{"Ocurrió un error al intentar enviar el mensaje."}</MuiAlert>
          </Snackbar>
        </form>
      </>
    )
  }
}

const styles = theme => ({
  contactButton: {
    color: "#ffffff",
    backgroundColor: "#ad398a",
    textAlign: "center",
    margin: "1em 0",
  },
  formField: {
    [theme.breakpoints.down("md")]: {
      width: "45%",
      margin: ".5em .4em",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      margin: ".5em 2em .5em 0",
    },
  },
})

export default withStyles(styles, { withTheme: true })(ContactForm)
