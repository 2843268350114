import React from "react"
import ContactForm from "./contact-form"
import { Grid, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    query ContactSectionQuery {
      messages: file(absolutePath: { regex: "/messages/" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }  
    }  
  `)

  const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: "center",
      fontSize: "2em",
      fontWeight: "500",
      margin: 0,
      [theme.breakpoints.down("md")]: {
        marginTop: "1em",
      },
    },
    formDescription: {
      fontSize: "1.25em",
    },
    envelope: {
      [theme.breakpoints.down("md")]: {
        margin: "2em",
      },
      [theme.breakpoints.up("md")]: {
        margin: "0 5em",
      },
    },
  }))
  const classes = useStyles()

  return (
    <>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item xs={12} md={4}>
          <p className={classes.title}>{"Trabajemos juntos"}</p>
        </Grid>
      </Grid>
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item xs={9} md={3}>
          <Img fluid={data.messages.childImageSharp.fluid} />
        </Grid>
        <Grid item xs={11} md={4} className={classes.formDescription}>
          <p>{"Envianos un mensaje y nos pondremos en contacto a la brevedad."}</p>
          <ContactForm />
        </Grid>
      </Grid>
    </>
  )
}

export default ContactSection
