import React, { useRef } from "react"
import Layout from "../components/common/layout"
import SEO from "../components/seo"
import ContactSection from "../components/contact/contact-section"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const ContactPage = () => {
  const contactRef = useRef()
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const useStyles = makeStyles((theme) => ({
    contactSection: {
      [theme.breakpoints.down("md")]: {
        marginTop: "4em",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "10em",
      },
    },
  }))
  const classes = useStyles()

  return (
    <>
      <SEO
        title={"Contacto"}
        description={data.site.siteMetadata.description}
        keywords={["contacto", "contact"]}
        url={"https://www.waravix.com/contact"}
      />
      <Layout currentPage={"contact"}>
        <div className={classes.contactSection}>
          <ContactSection contactRef={contactRef} />
        </div>
      </Layout>
    </>
  )
}

export default ContactPage
